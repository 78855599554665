import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import CheckOnboarding from './check-onboarding'
import DropboxSync from './dropbox-sync'
import Header from './header'
import SEO from './seo'

import './layout.css'

const Layout = ({
  pathname,
  title = '',
  skipOnboarding = false,
  appBarContent = null,
  upwards = null,
  children,
  SEOProps = {}
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <CheckOnboarding skip={skipOnboarding}>
        <DropboxSync />
        <SEO
          title={title || data.site.siteMetadata.title}
          pathname={pathname}
          keywords={[
            `fi`,
            `savings rate`,
            `calculator`,
            `personal finance`,
            `financial independence`,
          ]}
          {...SEOProps}
        />

        <div css={{ display: 'flex' }}>
          <Header
            upwards={upwards}
            siteTitle={title || data.site.siteMetadata.title}
            appBarContent={appBarContent}
          />
          <div css={{ marginTop: 56, flexGrow: 1, padding: 24 }}>
            {children}
          </div>
        </div>
      </CheckOnboarding>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
