import { navigate } from 'gatsby'
import React from 'react'
import { Subscribe } from 'unstated'

import FeaturesContainer from '../containers/FeaturesContainer'
import { FEATURES } from '../containers/FeaturesContainer'
import SettingsContainer from '../containers/SettingsContainer'
import { isBootstrapped } from '../containers/withLocalStorage'
import { isSSR } from '../util'

export default ({ skip = false, children }) =>
  isSSR ? (
    children
  ) : (
    <Subscribe to={[SettingsContainer, FeaturesContainer]}>
      {(settings, features) => {
        if (!isBootstrapped(settings) || !features.state._bootstrapped) {
          return null
        }

        const onboardingFeature = features.feature(FEATURES.onboarding)

        if (skip || onboardingFeature.off || settings.state.onboarded) {
          return children
        }

        const url = window.location.pathname

        navigate('/welcome', { state: { fromUrl: url } })
      }}
    </Subscribe>
  )
