import { Link } from 'gatsby'
import React from 'react'

import grey from '@material-ui/core/colors/grey'

import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'

import NewsIcon from '@material-ui/icons/Comment'
import NewUpdateIcon from '@material-ui/icons/FiberNew'
import SavingsIcon from '@material-ui/icons/MonetizationOn'
import TimeToFiIcon from '@material-ui/icons/Schedule'
import HelpOutIcon from '@material-ui/icons/SentimentVerySatisfied'
import SettingsIcon from '@material-ui/icons/Settings'
import FiJourneyIcon from '@material-ui/icons/Timeline'
import ContributionsIcon from '@material-ui/icons/Category'

import CheckLastUpdate from './check-last-update'
import DropboxStatus from './dropbox-status'

export default () => (
  <>    
    <List>
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <SavingsIcon />
        </ListItemIcon>
        <ListItemText primary="Savings" />
      </ListItem>
      <ListItem button component={Link} to="/totals">
        <ListItemIcon>
          <ContributionsIcon />
        </ListItemIcon>
        <ListItemText
          primary="Totals"
        />
      </ListItem>
      <ListItem button component={Link} to="/estimate">
        <ListItemIcon>
          <TimeToFiIcon />
        </ListItemIcon>
        <ListItemText
          primary="Time to FI"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiJourneyIcon />
        </ListItemIcon>
        <ListItemText
          primary={<em css={{ color: grey[400] }}>FI Journey (future)</em>}
        />
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListItem button component={Link} to="/updates">
        <ListItemIcon>
          <NewsIcon />
        </ListItemIcon>
        <ListItemText primary="Updates" />
        <ListItemSecondaryAction>
          <CheckLastUpdate>
            {({ hasSeenLastUpdate }) =>
              hasSeenLastUpdate ? null : (
                <IconButton>
                  <NewUpdateIcon color="secondary" />
                </IconButton>
              )
            }
          </CheckLastUpdate>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        button
        component="a"
        href="https://trello.com/c/oNNOPKfQ/8-%E2%9C%A8-start-here-%E2%9C%A8"
      >
        <ListItemIcon>
          <HelpOutIcon color="secondary" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: 'secondary' }}>
          Help out!
        </ListItemText>
      </ListItem>
      <ListItem button component={Link} to="/settings">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </List>
    <DropboxStatus />
  </>
)
