import { css } from 'emotion'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import NavigateBackIcon from '@material-ui/icons/ArrowBack'
import MenuIcon from '@material-ui/icons/Menu'

import { isSSR } from '../util'
import Nav from './nav'


const drawerWidth = 240
const styles = {
  grow: css`
    flex-grow: 1;
  `,
  header: css`
    flex-grow: 1;
    height: 56px;
    margin-bottom: 24px;
  `,
  appBar: css`
    margin-left: ${drawerWidth}px;
    @media screen and (min-width: 600px) {
      width: calc(100% - ${drawerWidth}px) !important;
    }
  `,
  menuButton: css`
    margin-left: -12;
    margin-right: 20;
    @media screen and (min-width: 600px) {
      &[aria-label="Menu"] {
        display: none !important;
      }
    }
  `,
  drawer: css`
    @media screen and (min-width: 600px) {
      width: ${drawerWidth}px;
      flex-shrink: 0;
    }
  `,
  drawerPaper: css`
    width: ${drawerWidth}px;
  `,
}

class Header extends React.Component {
  state = { showYearDialog: false, showMenu: false }

  render() {
    const { siteTitle, upwards = false, appBarContent = null } = this.props

    return (
      <>
        <AppBar position="fixed" className={styles.appBar}>
          <Toolbar>
            {!upwards && (
              <IconButton
                className={styles.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.handleMenuClick}
                data-testid="btn-nav-menu"
              >
                <MenuIcon />
              </IconButton>
            )}
            {upwards && (
              <IconButton
                className={styles.menuButton}
                color="inherit"
                aria-label="Back"
                onClick={this.goBack}
                data-testid="btn-nav-back"
              >
                <NavigateBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" color="inherit" className={styles.grow} data-testid="app-title">
              {siteTitle}
            </Typography>

            {appBarContent}
          </Toolbar>
        </AppBar>
        <nav className={styles.drawer}>
          {!isSSR && <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.showMenu}
              onClose={this.handleMenuClose}
              classes={{ paper: styles.drawerPaper }}
            >
              <Nav />
            </Drawer>
          </Hidden>}
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{ paper: styles.drawerPaper }}
            >
              <Nav />
            </Drawer>
          </Hidden>
        </nav>
      </>
    )
  }

  handleMenuClick = () => {
    this.setState({ showMenu: true })
  }

  handleMenuClose = () => {
    this.setState({ showMenu: false })
  }

  goBack = () => {
    navigate(this.props.upwards)
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
