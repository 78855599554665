import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { formatDistanceToNow } from 'date-fns'
import { Link } from 'gatsby'
import React from 'react'
import { Subscribe } from 'unstated'

import DropboxIcon, { SyncIcon } from './dropbox-icon'

import DropboxContainer, { SYNC_STATE } from '../containers/DropboxContainer'
import { isBootstrapped } from '../containers/withLocalStorage'

import { legacyParse } from "@date-fns/upgrade/v2";

const DropboxStatus = () => (
  <Subscribe to={[DropboxContainer]}>
    {dropboxStore => {
      const loaded = isBootstrapped(dropboxStore)

      if (!loaded) {
        return null
      }

      const syncState = dropboxStore.getSyncStatus()

      let secondaryText

      if (syncState === SYNC_STATE.SYNCING) {
        secondaryText = 'Syncing...'
      } else if (syncState === SYNC_STATE.SYNCED) {
        secondaryText = `${formatDistanceToNow(legacyParse(dropboxStore.getLastSynced()))} ago`
      } else if (syncState === SYNC_STATE.OFFLINE) {
        secondaryText = 'Device offline'
      } else if (syncState === SYNC_STATE.ERROR) {
        secondaryText = 'Error syncing'
      }

      return (
        <div css={{ marginTop: 'auto' }}>
          <Divider />
          <ListItem button component={Link} to="/integrations/dropbox">
            <ListItemIcon>
              {syncState === SYNC_STATE.SYNCING ? (
                <SyncIcon />
              ) : (
                <DropboxIcon />
              )}
            </ListItemIcon>
            {syncState === SYNC_STATE.NOT_CONNECTED ? (
              <ListItemText
                secondary="Sync device data"
                secondaryTypographyProps={{ variant: 'caption' }}
              >
                Setup Dropbox
              </ListItemText>
            ) : (
              <ListItemText
                secondary={secondaryText}
                secondaryTypographyProps={{ variant: 'caption' }}
              >
                {dropboxStore.state.bucket.name}
              </ListItemText>
            )}
          </ListItem>
        </div>
      )
    }}
  </Subscribe>
)

export default DropboxStatus
