import { Container } from 'unstated'

import withLocalStorage from './withLocalStorage'

class UpdatesContainer extends Container {
  state = { lastUpdateSeen: null }

  setLastUpdateSeen = id => {
    this.setState({ lastUpdateSeen: id })
  }
}

export default withLocalStorage(UpdatesContainer, {
  name: 'updates',
  version: 1,
})
