import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Subscribe } from 'unstated'

import FeaturesContainer from '../containers/FeaturesContainer'
import { FEATURES } from '../containers/FeaturesContainer'
import UpdatesContainer from '../containers/UpdatesContainer'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query checkLastUpdate {
        allMdx(sort: { fields: frontmatter___date, order: DESC }) {
          edges {
            node {
              id
            }
          }
        }
      }
    `}
    render={({ allMdx: { edges: posts } }) => (
      <Subscribe to={[UpdatesContainer, FeaturesContainer]}>
        {(updates, features) => {
          const latestUpdate = posts[0].node.id
          const hasSeenLastUpdate =
            features.feature(FEATURES.updates).off ||
            updates.state.lastUpdateSeen === latestUpdate

          return children({ hasSeenLastUpdate })
        }}
      </Subscribe>
    )}
  />
)
