import { Container } from 'unstated'

import { isSSR } from '../util'

/**
 * Parses feature flags from URL like e.g. #feature.feature1=true&feature.feature2=false
 *
 * @param {*} uri
 * @returns
 */
function flagsFromUrl(uri) {
  const { hash } = new URL(uri)
  const parts = hash ? hash.replace(/^#/, '').split('&') : []

  return parts.reduce((acc, part) => {
    const flagParts = part.split('=')

    if (flagParts.length === 2) {
      const feature = flagParts[0].replace(/feature\./i, '') // feature1
      const flag = flagParts[1] // true, false, string

      if (feature in FEATURES) {
        if (flag === 'true' || flag === 'false') {
          acc[feature] = flag === 'true'
        } else {
          acc[feature] = flag
        }
      }
    }

    return acc
  }, {});
}

export const FEATURES = {
  onboarding: 'onboarding',
  updates: 'updates',
  syncDropbox: 'sync:dropbox'
}
class FeaturesContainer extends Container {
  /**
   * Initial feature flag states
   *
   * @memberof FeaturesContainer
   */
  state = {
    _bootstrapped: false,
    [FEATURES.onboarding]: true,
    [FEATURES.updates]: true,
    [FEATURES.syncDropbox]: false
  }

  constructor() {
    super()

    if (!isSSR && window.location) {
      const urlFlags = flagsFromUrl(window.location)

      if (Object.keys(urlFlags).length) {
        super.setState(prevState => ({
          ...prevState,
          ...urlFlags,
          _bootstrapped: true,
        }))
      } else {
        super.setState({ _bootstrapped: true })
      }
    } else {
      super.setState({ _bootstrapped: true })
    }
  }

  feature(featureName) {
    return { on: !!this.state[featureName], off: !this.state[featureName] }
  }
}

export default FeaturesContainer
