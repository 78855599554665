import { applyChange } from 'deep-diff'
import { Dropbox } from 'dropbox'
import { Container } from 'unstated'

import { isSSR } from '../util'
import debounce from '../util/debounce'
import { createHasher } from '../util/dropbox-content-hasher'
import withLocalStorage from './withLocalStorage'

const DROPBOX_STORE = 'sync:dropbox'
const THRESHOLD_WAIT = 2000 // wait for 2 seconds to roll up all changes

export const SYNC_STATE = {
  NOT_CONNECTED: 0,
  SYNCED: 1,
  SYNCING: 2,
  OFFLINE: 3,
  ERROR: 4,
}

const log = (...args) => console.log('[dropbox]', ...args)

class DropboxContainer extends Container {
  state = {
    accessToken: null,
    accountName: null,
    bucket: null, // { id, name, lastSynced, rev }
    syncing: false,
    offline: false,
    error: false,
  }

  constructor() {
    super()

    if (!isSSR) {
      if ('onLine' in navigator) {
        this.state.offline = !navigator.onLine
      }

      window.addEventListener('offline', this.handleOffline)
      window.addEventListener('online', this.handleOnline)
    }
  }

  getSyncStatus = () => {
    if (this.hasAccount()) {
      if (this.state.offline) {
        return SYNC_STATE.OFFLINE
      }

      if (this.state.error) {
        return SYNC_STATE.ERROR
      }

      if (this.state.syncing) {
        return SYNC_STATE.SYNCING
      } else {
        return SYNC_STATE.SYNCED
      }
    }

    return SYNC_STATE.NOT_CONNECTED
  }

  handleOffline = () => {
    this.setState({ offline: true })
  }

  handleOnline = () => {
    this.setState({ offline: false })
  }

  beginSync = () => {
    this.setState({ syncing: true })
  }

  endSync = (rev = null, lastSynced = null) => {
    this.setState(prevState => {
      if (!rev && !lastSynced) {
        return { syncing: false }
      } else {
        return {
          syncing: false,
          bucket: { ...prevState.bucket, rev, lastSynced },
        }
      }
    })
  }

  hasAccount = () => !!this.state.bucket

  getLastSynced = () =>
    this.state.bucket ? this.state.bucket.lastSynced : null

  setLastSynced = timestamp =>
    this.state.bucket
      ? this.setState(prevState => ({
          ...prevState,
          bucket: {
            ...prevState.bucket,
            lastSynced: timestamp,
          },
        }))
      : null

  authenticate = (accessToken, user) => {
    return this.setState(prevState => ({
      ...prevState,
      accessToken,
      accountName: user.name,
    }))
  }

  useAndSyncBucket = async (bucket, onSync) => {
    await this.setState(
      {
        bucket,
      },
      async () => {
        this.beginSync()

        const downloaded = await this.download()

        this.endSync(
          downloaded.metadata.rev,
          downloaded.metadata.client_modified
        )

        return onSync(downloaded.contents, downloaded.metadata)
      }
    )
  }

  revoke = () => {
    return this.setState({ accessToken: null, accountName: null, bucket: null })
  }

  _performSync = async (changes, contents, onDownload) => {
    const dbx = new Dropbox({ accessToken: this.state.accessToken, fetch })
    const hasher = createHasher()

    if (!this.hasAccount()) {
      return
    }

    if (this.state.offline) {
      log('device is offline, ignoring sync')
      return
    }

    log('checking metadata of server bucket', this.state.bucket.id)

    this.beginSync()

    try {
      const serverFileMetadata = await dbx.filesGetMetadata({
        path: this.state.bucket.id,
      })

      // Compare content hashes to see if we should sync
      hasher.update(JSON.stringify(contents))
      const newContentHash = hasher.digest()

      if (newContentHash === serverFileMetadata.content_hash) {
        log('content hashes match, ignoring changes')
        this.endSync()
        return null
      }

      if (serverFileMetadata.rev !== this.state.bucket.rev) {
        log(
          'server content has changed, downloading and merging changes with local'
        )

        const {
          metadata: downloadedFile,
          contents: serverContents,
        } = await this.download()

        log('downloaded file from server')

        if (changes.length) {
          log('attempting to replay local changes over server changes')
          changes.forEach(change =>
            applyChange(serverContents, contents, change)
          )

          const newFileMetadata = await dbx.filesUpload({
            path: this.state.bucket.id,
            mode: 'overwrite',
            contents: JSON.stringify(serverContents),
            client_modified: new Date().toISOString().replace(/\.\d{3}/, ''),
            mute: true,
            autorename: false,
          })

          if (newFileMetadata) {
            log('successfully merged and uploaded changes')
            this.endSync(newFileMetadata.rev, newFileMetadata.client_modified)
            return onDownload(serverContents, newFileMetadata)
          }
        }

        this.endSync(downloadedFile.rev, downloadedFile.client_modified)
        return onDownload(serverContents, serverFileMetadata)
      } else if (serverFileMetadata.content_hash !== newContentHash) {
        log(
          'content hash differs, attempting to upload changes',
          'new hash:',
          newContentHash,
          'serverHash',
          serverFileMetadata.content_hash
        )

        const result = await dbx.filesUpload({
          path: this.state.bucket.id,
          mode: 'overwrite',
          contents: JSON.stringify(contents),
          client_modified: new Date().toISOString().replace(/\.\d{3}/, ''),
          mute: true,
          autorename: false,
        })

        if (result) {
          log('successfully synced')
          return this.endSync(result.rev, result.client_modified)
        }
      }
    } catch (err) {
      console.log(err)
      this.setState({ error: err })
    } finally {
      return this.endSync()
    }
  }

  sync = debounce(THRESHOLD_WAIT, this._performSync)()
  syncImmediate = this._performSync

  download = async () => {
    const dbx = new Dropbox({ accessToken: this.state.accessToken, fetch })

    const downloadedFile = await dbx.filesDownload({
      path: this.state.bucket.id,
    })

    const serverContents = await new Response(downloadedFile.fileBlob).json()

    return { metadata: downloadedFile, contents: serverContents }
  }
}

export default withLocalStorage(DropboxContainer, {
  name: DROPBOX_STORE,
  version: 1,
})
