import { diff } from 'deep-diff'
import React from 'react'
import { Subscribe } from 'unstated'

import DropboxContainer from '../containers/DropboxContainer'
import SavingsContainer from '../containers/SavingsContainer'
import { isBootstrapped } from '../containers/withLocalStorage'

import { isSSR } from '../util'

let prevState = null

const DropboxSync = () => {
  if (isSSR) {
    return null
  }
  return (
    <Subscribe to={[SavingsContainer, DropboxContainer]}>
      {(savingsData, dropboxContainer) => {
        if (!isBootstrapped(savingsData, dropboxContainer)) {
          return null
        }

        const changes = diff(prevState, savingsData.state)

        const onNextState = nextState => {          
          prevState = nextState
          savingsData.setState(nextState)
        }

        if (prevState && changes && changes.length) {
          if (dropboxContainer.hasAccount()) {
            dropboxContainer.sync(changes, savingsData.state, onNextState)
          }
        } else if (!prevState) {
          dropboxContainer.sync([], savingsData.state, onNextState)
        }

        prevState = savingsData.state

        return null
      }}
    </Subscribe>
  )
}

export default DropboxSync
